<template>
<div>

  <div id="mybutton">
    <a
      href="#"
      class="mb-12 btn btn-icon w-auto d-flex flex-row btn-lg px-2"
      @click.prevent="alertHelp"
      id="pop-help"
    >
      <span class="label label-circle bg-warning  text-white p-12 text-center font-weight-bolder">
          <i class="far fa-life-ring  text-white icon-2x"></i>
      </span>
    </a>
    <b-popover target="pop-plus" triggers="hover">
      <template v-slot:title>{{$t("INTRO.ASSISTENZA")}}</template>
    </b-popover>
    <!-- <a
      href="#"
      class="mb-5 btn btn-icon w-auto btn-lg px-2  d-flex flex-row "
      @click.prevent="alertInfo"
      id="pop-info"
    >
      <span class="label label-circle bg-warning  text-white p-12 text-center font-weight-bolder">
          <i class="fas fa-info-circle text-white icon-2x"></i>
      </span>
    </a>
    <b-popover target="pop-plus" triggers="hover">
      <template v-slot:title>{{$t("INTRO.INFO") /* #key_loc */}}</template>
    </b-popover> -->
  </div>

  <div v-html="get_block5">
      <!-- INTRO  -->
  </div>

</div>

</template>

<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";


export default {
  name: "intro",
  data() {
    return { };
  },
  computed: {
    ...mapGetters([
      "getOrganization"
    ]),
    get_block5(){
      return ((this.getOrganization.data && this.getOrganization.data.block5) ? this.getOrganization.data.block5 : '');
    }

  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MAINMENU.HOME")}]);
  },
  methods: {
    alertInfo(){
      Swal.fire({
        title: this.$t("HOME.ALERT1TITLE"),
        html: this.$t("HOME.ALERT1"),
        icon: "info",
        confirmButtonClass: "btn btn-primary"
      });
    },
    alertHelp(){
      Swal.fire({
        title: this.$t("HOME.ALERT2TITLE"),
        html: this.$t("HOME.ALERT2"),
        icon: "info",
        confirmButtonClass: "btn btn-primary"
      });
    },
  }
};
</script>
